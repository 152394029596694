import React, { Dispatch, FC, memo, SetStateAction, useContext } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import { ProjectListItem } from 'src/entity/ProjectListItem';
import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/kit/plus.svg';
import { ReactComponent as LogoIcon } from 'src/shared/assets/icons/logo.svg';
import { useResize } from 'src/shared/lib/hooks/useResize';
import { Loader } from 'src/shared/ui/Loader';

interface ProjectListProps {
  handleOpenCreateProjectModal: () => void;
  setIsSmall: Dispatch<SetStateAction<boolean>>;
  setWidth: Dispatch<SetStateAction<number>>;
  setSearch: Dispatch<SetStateAction<string>>;
}

export const ProjectList: FC<ProjectListProps> = memo(
  ({ handleOpenCreateProjectModal, setIsSmall, setWidth, setSearch }) => {
    const { projects, isProjectsLoading } = useContext(ProjectsContext);
    const { user } = useContext(UserContext);

    const setBig = () => {
      localStorage.setItem('sidebar', 'big');
      setIsSmall(false);
      setSearch('');
      setWidth(320);
    };

    const callBack = (width: number) => {
      setWidth(width + 224);
      if (width > 200) {
        setBig();
      }
    };

    const { onDragStart, onDrag } = useResize({ id: 'resizable-small', callBack });

    return (
      <div
        className="relative flex w-16 flex-shrink-0 flex-col items-center bg-blue-gradient-100"
        id="resizable-small"
      >
        <div
          className="absolute right-0 top-0 z-10 h-full w-2 cursor-col-resize"
          draggable="true"
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDoubleClick={setBig}
        />
        <div className="flex h-16 items-center">
          <LogoIcon className="h-6 w-6" />
        </div>
        <div className="relative flex w-full flex-1 flex-col overflow-auto">
          {!projects.length && isProjectsLoading ? (
            <div className="flex h-28 w-full items-center justify-center">
              <Loader classNames="w-10 h-10" />
            </div>
          ) : null}

          {!projects.length && !isProjectsLoading ? (
            <div className="flex h-14 min-h-14 w-full items-center justify-center">
              <div className="font-gothampro-400 tex-sm text-white">Проекты не найдены</div>
            </div>
          ) : null}

          {projects.length && !isProjectsLoading
            ? projects.map((project, i) => (
                <ProjectListItem key={`project-${i}`} project={project} />
              ))
            : null}
        </div>

        {user?.isAdmin && (
          <div className="relative flex-shrink-0 py-4">
            <button
              className="flex h-8 w-8 items-center justify-center rounded bg-violet-1300"
              onClick={handleOpenCreateProjectModal}
            >
              <PlusIcon className="h-4 w-4 fill-white" />
            </button>
          </div>
        )}
      </div>
    );
  },
);
