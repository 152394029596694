import { Dispatch, SetStateAction, useContext } from 'react';
import { CellLocation, Id, MenuOption, SelectionMode } from '@silevis/reactgrid';
import { TableContext } from 'src/app/providers/TableProvider';
import { UserContext } from 'src/app/providers/UserProvider';

interface UseContextMenuProps {
  isIdHidden: boolean;
  setIsIdHidden: Dispatch<SetStateAction<boolean>>;
  setIsOpenEditModal: Dispatch<SetStateAction<boolean>>;
}

export const useContextMenu = ({
  isIdHidden,
  setIsIdHidden,
  setIsOpenEditModal,
}: UseContextMenuProps) => {
  const { setIsEdit, addEmptyRows, addEmptyTopRows, isEdit, setTableData } =
    useContext(TableContext);
  const { user } = useContext(UserContext);

  const editMenu = (rows: number[]) => [
    {
      id: 'addRowBottom',
      label: 'добавить строку снизу',
      handler: () => addEmptyRows(1),
    },
    {
      id: 'addRowTop',
      label: 'добавить строку сверху',
      handler: () => addEmptyTopRows(),
    },
    {
      id: 'removeRow',
      label: `удалить ${rows.length > 1 ? 'строки' : 'строку'}`,
      handler: () => {
        if (rows.length) {
          setTableData(prev => prev.filter((_, i) => !rows.includes(i)));
        }
      },
    },
  ];

  const mainMenu = [
    {
      id: 'edit',
      label: 'редактировать',
      handler: () => setIsEdit(prev => !prev),
    },
    {
      id: 'id',
      label: isIdHidden ? 'показать ID' : 'скрыть ID',
      handler: () => setIsIdHidden(prev => !prev),
    },
    {
      id: 'import',
      label: 'импорт',
      handler: () => {},
    },
    {
      id: 'export',
      label: 'экспорт',
      handler: () => {},
    },
  ];

  const developerMenu = [
    {
      id: 'editTable',
      label: 'редактровать',
      handler: () => setIsOpenEditModal(true),
    },
  ];

  const simpleHandleContextMenu = (
    selectedRowIds: Id[],
    selectedColIds: Id[],
    selectionMode: SelectionMode,
    menuOptions: MenuOption[],
    selectedRanges: Array<CellLocation[]>,
  ): MenuOption[] => {
    if (user?.isDeveloper) {
      return developerMenu;
    }

    if (isEdit) {
      const selectedRows: number[] =
        [...new Set(selectedRanges?.[0]?.map(row => row.rowId as number))] || [];

      return editMenu(selectedRows);
    }
    return mainMenu;
  };

  return { simpleHandleContextMenu };
};
