import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AuthService from 'src/shared/api/auth/AuthService';
import TokenService from 'src/shared/api/token/TokenService';
import { emptyError } from 'src/shared/const';
import { isEmptyObject, setError } from 'src/shared/lib/utils';
import { UserContext } from 'src/shared/store/user';
import { LoginType } from 'src/shared/types';
import { Button, Input } from 'src/shared/ui';

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginType>();

  const disabled = !isEmptyObject(errors);
  const { setIsUserLoading, setUser } = useContext(UserContext);

  const onSubmit: SubmitHandler<LoginType> = async params => {
    try {
      const { data } = await AuthService.login(params);
      const { user, ...tokens } = data;

      TokenService.setTokens(tokens);
      setUser(user);
    } catch (e) {
      if (e.response?.status === 401) {
        setError('Неверный логин или пароль');
      }
    } finally {
      setIsUserLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-96 w-[33rem] flex-col items-center justify-between"
    >
      <div className="font-gothampro-500 text-2xl">Авторизация</div>

      <div className="w-full">
        <Input
          {...register('email', { required: emptyError })}
          error={errors.email?.message}
          placeholder="Введите логин"
          label="Логин"
          containerClassNames="mb-5"
          variant="grey"
        />
        <Input
          {...register('password', { required: emptyError })}
          error={errors.password?.message}
          placeholder="Введите пароль"
          type="password"
          label="Пароль"
          variant="grey"
        />
      </div>

      <div className="font-gothampro-400 flex">
        Забыли пароль?
        <Link to="/reset" className="ml-2 text-blue-1400 underline">
          Восстановить
        </Link>
      </div>

      <Button variant="violet" disabled={disabled} classNames="w-96" type="submit">
        Войти
      </Button>
    </form>
  );
};
