import React, { createContext, FC, ReactNode, useState } from 'react';
import { userContextDefaultState, userRolesEnum } from 'src/shared/store/user/constants';
import { IUserContext, UserType } from 'src/shared/store/user/types';

export const UserContext = createContext<IUserContext>(userContextDefaultState);

interface IUserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<IUserProviderProps> = ({ children }) => {
  const { user: _user, isUserLoading: _isUserLoading } = userContextDefaultState;

  const [user, setUser] = useState<UserType | null>(_user);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(_isUserLoading);
  const [darkMode, setDarkMode] = useState<boolean>(false);

  const handleSetUser = (user: UserType | null) => {
    if (!user) {
      return setUser(null);
    }
    const role = user.role;

    if (role === userRolesEnum.ADMIN) {
      return setUser({ ...user, isAdmin: true });
    }

    if (role === userRolesEnum.DEVELOPER) {
      return setUser({ ...user, isDeveloper: true });
    }

    if (role === userRolesEnum.OPERATOR) {
      return setUser({ ...user, isOperator: true });
    }

    setUser(user);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: handleSetUser,
        isUserLoading,
        setIsUserLoading,
        darkMode,
        setDarkMode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
