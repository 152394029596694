import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AuthService from 'src/shared/api/auth/AuthService';
import { emptyError } from 'src/shared/const';
import { isEmptyObject, setError, setSuccess } from 'src/shared/lib/utils';
import { LoginType, ResetType } from 'src/shared/types';
import { Button, Input } from 'src/shared/ui';

export const ResetForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetType>();

  const disabled = !isEmptyObject(errors);

  const onSubmit: SubmitHandler<LoginType> = async params => {
    try {
      await AuthService.reset(params);

      setSuccess('Сообщение отправленно вам на почту');
    } catch (e) {
      setError('Учетной записи с таким логином не существует');
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-96 w-[33rem] flex-col items-center justify-between"
    >
      <div className="font-gothampro-500 text-2xl">Забыли пароль?</div>
      <div className="font-gothampro-400 text-center">
        Введите адрес электронной почты, который вы использовали при регистрации, и мы отправим вам
        новый пароль.
      </div>

      <div className="w-full">
        <Input
          {...register('email', { required: emptyError })}
          error={errors.email?.message}
          placeholder="Введите email"
          containerClassNames="mb-5"
          variant="grey"
        />
      </div>

      <div className="flex flex-col gap-4">
        <Button variant="violet" disabled={disabled} classNames="w-96" type="submit">
          Восстановить пароль
        </Button>
        <Link to="/login" className="font-gothampro-400 skip-ink text-center underline">
          назад
        </Link>
      </div>
    </form>
  );
};
